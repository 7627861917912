var $ = require("jquery");
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

/* ====================== BRANDS SLIDER ======================  */
   var newsSlider = new Swiper('.news-block__slider', {
      slidesPerView: 1.15,
      spaceBetween: 13,
      allowTouchMove: true,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 2.15,
          spaceBetween: 25,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 25,
        },
      },
  });

/* ====================== BRANDS SLIDER ======================  */
   var videoSlider = new Swiper('.video-gallery__slider', {
      slidesPerView: 1.15,
      spaceBetween: 13,
      allowTouchMove: true,
      breakpoints: {
        768: {
          slidesPerView: 2.15,
          spaceBetween: 25,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 25,
        },
      },
  });

    videoSlider.on('slideChange', function (e) {
        $('.video-card__thumbnail').removeClass('video-card__thumbnail--hide');
        $('.video-card__vid').each(function() {
          this.pause();
          this.currentTime = 0;
        });
    });

/* ====================== QUOTES SLIDER ======================  */
   var newsSlider = new Swiper('.quotes__slider', {
      slidesPerView: 1.15,
      spaceBetween: 13,
      allowTouchMove: true,
      breakpoints: {
        768: {
          slidesPerView: 2.25,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
  });

/* ====================== GALLERY SLIDER ======================  */
    var galleryThumbsSwiper = new Swiper(".gallery__slider--thumbs", {
      spaceBetween: 13,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesProgress: true,
      breakpoints: {
        481: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 4,
        },
        1025: {
          slidesPerView: 6,
        },
        1200: {
          slidesPerView: 6,
        },
      },
    });
    var galleryMainSwiper = new Swiper(".gallery__slider--main .swiper", {
      slidesPerView: 1,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next.gallery__next",
        prevEl: ".swiper-button-prev.gallery__prev",
      },
      thumbs: {
        swiper: galleryThumbsSwiper,
      },
    });

/* ====================== BRANDS SLIDER ======================  */
   var newsSlider = new Swiper('.vide-gallery__slider', {
      slidesPerView: 1.15,
      spaceBetween: 13,
      allowTouchMove: true,
      breakpoints: {
        768: {
          slidesPerView: 2.15,
          spaceBetween: 25,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 25,
        },
      },
  });

FontAwesomeConfig = { searchPseudoElements: true };
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';

jQuery(document).ready(function($) {
/* ====================== SMOOTH SCROLL TO HASH ======================  */
    if(window.location.hash) {
      var headerHeight = $('.header').height() + 50;
      var hash = window.location.hash;
      $('html, body').animate({
        scrollTop: $(hash).offset().top - headerHeight
      }, 1000 );
    }

/* ====================== MEMBER TABLE ROW TOGGLE ======================  */
  $('.member-list__body .member-list__row').on("click", function() {
    $(this).toggleClass('member-list__row--active');
    $(this).find('.member-list__column').last().slideToggle();
  })

/* ====================== CLICK FUNCTIONS ======================  */
    $('.video-card__thumbnail').on('click', function(e) {
        var video = $(this).next('.video-card__vid');
        $(this).addClass('video-card__thumbnail--hide');
        video[0].play();
    });

 /* ====================== NAV TOGGLE CLICK ======================  */
    const buttonElement = document.querySelector('.menuToggle');
    if(buttonElement) {
        buttonElement.addEventListener('click', function (event) {
          let expanded = this.getAttribute('aria-expanded') === 'true';
          this.setAttribute('aria-expanded', !expanded);
          event.preventDefault();

          let body = document.querySelector("body, html");
          let navigation = document.querySelector(".navigation");
          body.classList.toggle("noscroll");
          navigation.classList.toggle('navigation--active');
        });
    }


/* ====================== TEAM PROFILE ANIMATION ======================  */
    $('.person').click(function() {
        var bio = $(this).find('.person__bio');
        var currentHeight = bio.height();
        var autoHeight = bio.css('height', 'auto').height();

         $('.person').removeClass('person--active');

        if (currentHeight == 0) {
            bio.height(currentHeight).animate({ height: autoHeight, opacity: 1 }, 500, function() {
              bio.css('height', 'auto');
            });
            $(this).addClass('person--active');
        } else {
            bio.animate({ height: "0", opacity: 0 }, 500);
        }

         $('.person').not($(this)).find('.person__bio').delay(500).animate({ height: "0", opacity: 0 });
    });

/* ====================== HASH URL SMOOTH SCROLL ======================  */
    // $(document).on('click', 'a[href^="#"]', function (event) {
    //     event.preventDefault();
    //     headerHeight = $(".header").outerHeight() + 50;
    //     $('html, body').animate({
    //         scrollTop: $($.attr(this, 'href')).offset().top - headerHeight
    //     }, 500);
    // });

/* ====================== INIT FUNCSTIONS ======================  */

/* ====================== WINDOW REISZE FUNCTION  ======================  */
  $(window).resize(function() {
  })
});

jQuery(document).ready(function($) {
    $(document).on('input change', '.tito-quantity-input', function() {
        var formWrapper = $(this).closest('.event-registration__main');
        calculateTotal(formWrapper);
    });
    $(document).on("click", ".tito-release--increment-quantity--link, .tito-release--decrement-quantity--link", function() {
        var formWrapper = $(this).closest('.event-registration__main');
        calculateTotal(formWrapper);
    });

    function getCurrencySymbol(string) {
      const regex = /\p{Sc}/u;
      const currencySymbol = string.match(regex);
      if (currencySymbol) {
        return currencySymbol[0];
      } else {
        return null;
      }
    }

    function calculateTotal(formWrapper) {
        var total = 0;
        var inputs = {};
        var symbol = "";

        // Loop through the input elements within the same form wrapper
        formWrapper.find('.tito-quantity-input').each(function() {
            var input = $(this);
            var inputId = input.attr('id');
            var inputName = $('label[for="' + inputId + '"]').text();
            var inputValue = parseFloat(input.val()) || 0;
            var inputMoney = input.closest('.tito-release').find('.tito-price').html();
            var inputMoneyFinal = parseFloat(inputMoney.replace(/[^\d\.]/g, ''));

            symbol = getCurrencySymbol(inputMoney);

            if (!isNaN(inputValue) && inputValue != 0) {
                inputs[inputName] = inputValue;
                total += inputMoneyFinal * inputValue;
            }
            console.log(total);
        });

        var myList = $('.sidebar__results');
        myList.empty();
        $.each(inputs, function(index, item) {
            var listItem = $('<li>').html("<span>" + index + "</span> x" + item);
            myList.append(listItem);
        });

        var myListTotal = $('.sidebar__total');
        if (total > 0) {
            myListTotal.html("<strong>Price " + symbol + total.toFixed(2) + "</strong>");
        } else {
            var listItem = $('<li>').html("You currently have no tickets selected");
            myList.append(listItem);
            myListTotal.html("");
        }
    }

    $('.event-registration__tab').on('click', function() {
        $('.event-registration__tab').removeClass('active');
        $(this).addClass('active');
        var targetClass = $(this).hasClass('event-registration__tabs--gbp') ? 'event-registration__main--gbp' : 'event-registration__main--eur';
        $('.event-registration__main').removeClass('active');
        $('.' + targetClass).addClass('active');
        var formWrapper = $('.' + targetClass);
        calculateTotal(formWrapper);
    });
});
